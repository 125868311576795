<template>
  <div class="station-flaw">
    <div class="damage-value-box">
      <div class="title">
        <p>损伤量值趋势图</p>
      </div>
      <div id="damage_value" class="damage-value-charts"></div>
    </div>
    <div class="damage-site-box">
      <div class="title">
        <p>损伤位置偏差图</p>
      </div>
      <div id="damage_site" class="damage-site-charts"></div>
    </div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import { nextTick, onMounted, watch, ref ,onUnmounted } from "vue";
import { GetStationFlawTrend } from "@/common/api/index/api.js";
import {
  getDamageValueAntvG2Charts,
  updateDamageValueAntvG2Charts,
} from "@/hooks/detail/antv/stationFlaw/damageValueCharts.js"; //引入封装的Antv G2 组件

import {
  getDamageSiteAntvG2Charts,
  updateDamageSiteAntvG2Charts,
} from "@/hooks/detail/antv/stationFlaw/damageSiteCharts.js"; //引入封装的Antv G2 组件
export default {
  name: "",
  setup(props) {
    const httpGetStationFlawTrend = async (ruleForm) => {
      const res = await GetStationFlawTrend(ruleForm);
      const flawData = res.data.data;
      const damageValueArr = computedAntvDamageValueData(flawData); //组装损伤量值
      const damageSiteArr = computedAntvDamageSiteData(flawData); //组装损伤位置偏差

      return { damageValueArr, damageSiteArr };
    };

    let chartValue = null;
    let chartSite = null;
    let maxSite = ref(0);
    let minSite = ref(0);
    //let chartRef = ref({});

    const initAntvG2DamageValueCharts = () => {
      chartValue = new Chart({
        container: document.querySelector("#damage_value"),
        autoFit: true,
      });

      //chartRef.value = chart;
    };

    const initAntvG2DamageSiteCharts = () => {
      chartSite = new Chart({
        container: document.querySelector("#damage_site"),
        autoFit: true,
      });
    };

    const computedAntvDamageValueData = (flawData) => {
      const damageValueArr = [];
      for (let i = 0; i < flawData.length; i++) {
        const tempObj = {};
        tempObj.date = flawData[i][0];
        tempObj.value = parseFloat(flawData[i][2].toFixed(1));
        damageValueArr.push(tempObj);
      }
      return damageValueArr;
    };

    const computedAntvDamageSiteData = (flawData) => {
      const damageSiteArr = [];
      for (let i = 0; i < flawData.length; i++) {
        const tempObj = {};
        tempObj.date = flawData[i][0];
        tempObj.value = parseFloat(flawData[i][1].toFixed(2));
        damageSiteArr.push(tempObj);
      }
      return damageSiteArr;
    };

    const getMaxMinDamageSiteData = (flawData) => {
      const siteValueArr = flawData.map((v) => v.value);
      const newFlawData = siteValueArr.sort(function (a, b) {
        return b - a;
      });
      maxSite.value = newFlawData[0];
      minSite.value = newFlawData[newFlawData.length - 1];
    };

    watch(
      () => props.stationFlowRule,
      async (ruleForm) => {
        const { damageValueArr, damageSiteArr } = await httpGetStationFlawTrend(
          ruleForm
        );
        updateDamageValueAntvG2Charts(chartValue, damageValueArr);

        getMaxMinDamageSiteData(damageSiteArr);
        updateDamageSiteAntvG2Charts(chartSite, damageSiteArr,maxSite.value,minSite.value);
      },
      { deep: true, immediate: false }
    );


    watch(
      () => props.isDestory,
       () => {
          chartValue.destroy();
          chartSite.destroy();
      },
      { deep: true, immediate: false }
    );
   

    onMounted(() => {
      nextTick(async () => {
        const { damageValueArr, damageSiteArr } = await httpGetStationFlawTrend(
          props.stationFlowRule
        );
        
        initAntvG2DamageValueCharts();
        getDamageValueAntvG2Charts(chartValue, damageValueArr);

        getMaxMinDamageSiteData(damageSiteArr);
        initAntvG2DamageSiteCharts();
        getDamageSiteAntvG2Charts(chartSite, damageSiteArr,maxSite.value,minSite.value);
      });
    });

    onUnmounted(() => {
          console.log(2222)
    });

    return {
      initAntvG2DamageValueCharts,
    };
  },
  data() {
    return {};
  },

  mounted() {},

  props: {
    stationFlowRule: [Object],
    isDestory:Boolean
  },
};
</script>

<style lang="scss" scoped>
.station-flaw {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .damage-value-box {
    height: 4.375rem;
    background-color: #f0f0f0;
    padding: 20px;
    width: 90%;
    .title {
      color: #333;
      font-size: 18px;
      margin-bottom: 20px;
      text-align: center;
    }
    .damage-value-charts {
      width: 90%;
      height: 3.75rem;
      margin:  0 auto;
      // background-color: #f0f0f0;
    }
  }
  .damage-site-box {
    height: 4.375rem;
     width: 90%;
    background-color: #f0f0f0;
    padding: 20px;
    margin-top: 20px;
    .title {
      color: #333;
      font-size: 18px;
      margin-bottom: 20px;
      text-align: center;
    }
    .damage-site-charts {
      width: 90%;
      height: 3.75rem;
    margin:  0 auto;
      // background-color: #f0f0f0;
    }
  }
}
</style>