import {
    registerInteraction
} from "@antv/g2";
// import {
//     ref
// } from "vue";

// const chart = ref("");

function isWheelDown(event) {
    event.gEvent.preventDefault();
    return event.gEvent.originalEvent.deltaY > 0;
}

//自定义拖拽功能
registerInteraction("drag-move", {
    start: [{
        trigger: "plot:mousedown",
        action: "scale-translate:start"
    }],
    processing: [{
        trigger: "plot:mousemove",
        action: "scale-translate:translate",
        throttle: {
            wait: 100,
            leading: true,
            trailing: false
        },
    }, ],
    end: [{
        trigger: "plot:mouseup",
        action: "scale-translate:end"
    }],
});

//自定义缩放功能
registerInteraction("view-zoom", {
    start: [{
            trigger: "plot:mousewheel",
            isEnable(context) {
                return isWheelDown(context.event);
            },
            action: "scale-zoom:zoomOut",
            // arg: {  wheelDelta: 10000 },
            throttle: {
                wait: 300,
                leading: true,
                trailing: false
            },
        },
        {
            trigger: "plot:mousewheel",
            isEnable(context) {
                return !isWheelDown(context.event);
            },
            action: "scale-zoom:zoomIn",
            // arg: {  wheelDelta: 10000 },
            throttle: {
                wait: 300,
                leading: true,
                trailing: false
            },
        },
    ],
});


// const initAntvG2Charts = (chatsDom) => {
//     // 声明可视化
//     console.log(chatsDom);
//     chart.value = new Chart({
//         //container: document.querySelector("#container"),
//         container: chatsDom, //获取DOM
//         autoFit: true,
//         // width: "auto",
//         // height: 200,
//     });

// };


const setAntvG2ChartsData = (chart, data) => {
    chart.data(data);
};


const setAntvG2ChartsOptions = (chart) => {
    chart.scale({

        date: {
            range: [0, 1],
            tickCount: 30,
            nice: true,
            mask: "YY-MM-DD HH:mm:ss"
        },

        value: {
            min: 0,
            max: 10,
            tickCount: 6,
            nice: true,
        },
    });

    chart.axis("date", {

        label: {
            formatter: (val) => {
                let date = val.substr(0, 9);
                let time = val.substr(10, 20);
                return date + "\n\n" + time;
            },
        },
    })
    chart.axis("value", {
        grid: {
            line: {
                type: "circle",
                closed: false,
                lineWidth: 3,
                style: {
                    fill: '#FFF',
                    stroke: "#000",
                    opacity: 0.3,
                    lineDash: [1, 3], //虚线
                },
            },
        },
        // line: null, //隐藏坐标轴
        label: {
            formatter: (val) => {
                return val + '%';
            },
        },
        line: {
            style: {
                stroke: "#ccc", //坐标轴颜色
                lineWidth: 1,
            },
        },
    });

    chart.tooltip({
        showCrosshairs: false, // 展示 Tooltip 辅助线
        shared: true,
        //containerTpl: `<div class="g2-tooltip" ><p class="g2-tooltip-title"></p><ul class="g2-tooltip-list"></ul></div>`,
        itemTpl: `
        <ul class="g2-tooltip-list">
          <li class="g2-tooltip-list-item">
            <span class="g2-tooltip-marker"></span>
            <span class="g2-tooltip-name">损伤量值</span>:<span class="g2-tooltip-value">{value}%</span>
          </li>
        </ul>
        `,
    });



    // chart.interaction("view-zoom");
    // chart.interaction("drag-move");
    let lineObj = chart.line();
    lineObj.style({
        lineWidth: 2,
    });
    lineObj.color("#F4A460").position("date*value");
    //lineObj.label("value"); //是否显示数字
    //chart.point().position("date*value");
};

const antvG2ChartsRender = (chart) => {
    chart.render();
    chart.getCanvas().on('mousewheel', ev => {
        ev.preventDefault();
    })
};

//获取AntvG2折线图 步骤
export function getDamageValueAntvG2Charts(chart, chatsData) {
    setAntvG2ChartsData(chart, chatsData); //传入所需的数据
    setAntvG2ChartsOptions(chart); //设置折线图相关参数
    antvG2ChartsRender(chart); //更新图标
}


export function updateDamageValueAntvG2Charts(chart, chatsData) {
    setAntvG2ChartsData(chart, chatsData); //传入所需的数据
    antvG2ChartsRender(chart); //更新图标
}