<template>
  <div class="harm-box">
    <div class="harm-echart" ref="harmEcharts" id="harmEchatsId"></div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import { nextTick, onMounted, reactive, ref, watch, computed } from "vue";
import { GetStationFlawData } from "@/common/api/index/api.js";
import {
  computedStationFlawData,
  getFlawEchartsOptions,
} from "@/hooks/detail/echarts/flawEcharts.js"; //定义 ruleFrom rules
import { debounce } from "@/common/scrollToSlow.js";
import { useStore } from "vuex";

const props = defineProps({
  id: [Number, String],
  flawDataRule: [Object],
});

const harmEcharts = ref(null);
let echartsObj = null;
//const flawLevel = ["轻微", "轻度", "中度", "较重", "严重", "超限"];
const store = useStore();

const ruleForm = reactive({
  id: 0,
  filter_value: "",
  filter_level: 0,
  sort: 0,
  reverse: 0,
});

const optionsConfig = reactive({
  option: {},
});

const lang = computed(() => {
  return store.state.lang.lang;
});

let flawListData = [];

const httpGetStationFlawData = async (ruleForm) => {
  const res = await GetStationFlawData(ruleForm);
  flawListData = computedStationFlawData(res.data.flaw_list);
  optionsConfig.option = getFlawEchartsOptions(flawListData, false, lang.value);
  //渲染echarts图表
  echartsObj.setOption(optionsConfig.option);
};

watch(
  () => lang,
  () => {
    optionsConfig.option = getFlawEchartsOptions(
      flawListData,
      false,
      lang.value
    );
    echartsObj.setOption(optionsConfig.option);
  },
  { deep: true, immediate: false }
);

watch(
  () => props.id,
  (id) => {
    ruleForm.id = id;
    //初始化echarts
    echartsObj = echarts.init(harmEcharts.value);
    httpGetStationFlawData(ruleForm); //先请求数据 组装数据
  },
  { deep: true, immediate: false }
);


watch(
  () => props.flawDataRule,
  (flawDataRule) => {
    httpGetStationFlawData(flawDataRule);
  },
  { deep: true, immediate: false }
);


watch(
  () => store.state.newTimes,
  (newTimes, oldTimes) => {
    echartsObj && echartsObj.clear();
    httpGetStationFlawData(ruleForm);
    if (oldTimes !== 0) {
      // echartsObj && echartsObj.clear();
      // httpGetStationFlawData(ruleForm);
    }
  },
  { deep: true, immediate: false }
);

onMounted(() => {
  nextTick(() => {
    //initHarmEchats();
    window.addEventListener(
      "resize",
      debounce(() => {
        echartsObj.resize();
        // echartsObj.setOption(optionsConfig.option);
      })
    );
  });
});
</script>

<style lang="scss" scoped>
.harm-box {
  .harm-echart {
    width: 5.25rem;
    height: 5rem;
  }
}
</style>