<template>
  <div class="detail" id="detail1" :class="{ 'fixed-detail': isFullScreen }">
    <el-container style="height: auto">
      <el-header height="auto">
        <Header></Header>
      </el-header>
      <el-main style="padding-top: 0; overflow-y: hidden">
        <div class="main-box">
          <div class="top">
            <div class="station-info">
              <station :id="id" @getStationInfo="getStationInfo"></station>
              <div class="run-box">
                <div class="report" @dblclick="handleClickReport">
                  <img src="@/assets/icon/report.png" alt="" />
                </div>
                <div class="run-text" v-if="Object.keys(stationSt).length > 0">
                  <p>
                    {{ langObj.detail.total_mileage }}：<span
                      >{{ weekRunLength }}km</span
                    >
                  </p>
                  <p>
                    {{ langObj.detail.total_run_duration }}：<span
                      >{{ weekRunTime }}h</span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="damage-list">
              <harmList
                :id="id"
                @filterFlawDataRule="filterFlawDataRule"
              ></harmList>
            </div>
            <div class="damage-img">
              <dv-border-box-10 :color="['#2E6099', '#0DE7C2']">
                <div class="damage-box">
                  <div class="title">
                    <P>{{ langObj.detail.damage_grading }}</P>
                  </div>
                  <harmEcharts
                    :id="id"
                    :flawDataRule="flawDataRule"
                  ></harmEcharts>
                </div>
              </dv-border-box-10>
            </div>
          </div>
          <div class="bottom">
            <div class="weekly-box">
              <dv-border-box-10 :color="['#2E6099', '#0DE7C2']">
                <div class="weekly-inner">
                  <div class="title">
                    <p>{{ langObj.detail.weekly_report }}</p>
                  </div>
                  <div class="weeklist">
                    <weekList
                      :id="id"
                      :clickReport="clickReport"
                      @getAllWeekLengthAndTime="getAllWeekLengthAndTime"
                      @changeReportDialog="changeReportDialog"
                    ></weekList>
                  </div>
                </div>
              </dv-border-box-10>
            </div>
            <!-- style="box-shadow:rgb(46, 96, 153) 0px 0px 3px 2px inset" -->
            <div class="wave-form">
              <dv-border-box-10 :color="['#2E6099', '#0DE7C2']">
                <div class="top">
                  <div class="title">
                    <p>{{ langObj.detail.waveform }}</p>
                  </div>
                  <div class="handle">
                    <p class="sensor-check">
                      <el-checkbox
                        v-model="zoomScaleY"
                        :label="langObj.detail.yscaling"
                        size="large"
                      />
                      <el-checkbox
                        v-model="cuverFlag"
                        :label="langObj.detail.sensor"
                        size="large"
                      />
                    </p>

                    <p>
                      <el-button
                        type="success"
                        size="small"
                        @click="resetAntvRender"
                        >{{ langObj.detail.reset }}</el-button
                      >
                    </p>
                  </div>
                </div>
                <div class="rope-box">
                  <!-- <waveForm
                    :id="id"
                    :cuverFlag="cuverFlag"
                    :resetNum="resetNum"
                    @getChartRef="getChartRef"
                  ></waveForm> -->
                  <component
                    :is="currentComponent"
                    :id="id"
                    :cuverFlag="cuverFlag"
                    :resetNum="resetNum"
                    :zoomScaleY="zoomScaleY"
                    @getChartRef="getChartRef"
                    :key="$route.name"
                    @changeCuverFlagVis="changeCuverFlagVis"
                  ></component>
                </div>
              </dv-border-box-10>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import Header from "@/components/header/header";
import station from "@/components/detail/station/station";
import harmList from "@/components/detail/harm/harmList";
import harmEcharts from "@/components/detail/harm/harmEcharts";
import weekList from "@/components/detail/weekReport/weekList";
import waveForm from "@/components/detail/cuver/waveForm";
import waveFormDt from "@/components/detail/cuver/waveFormDt";
import { debounce } from "../../common/scrollToSlow.js";
import {
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
  onUnmounted,
  computed,
} from "vue";
import { useRoute } from "vue-router";
import { GetStState } from "@/common/api/index/api.js";
import { useStore } from "vuex";

let route = useRoute();
let id = ref(0);
// let filter_value = ref("");
let cuverFlag = ref(false);
let resetNum = ref(0);
let currentComponent = ref("");
let weekRunLength = ref(0);
let weekRunTime = ref(0);
let clickReport = ref(false);
let zoomScaleY = ref(false);

const store = useStore();

let isFullScreen = ref(false);

const langObj = computed(() => {
  return store.state.lang.list;
});

let chartRef = reactive({
  chartRef: {},
});

let stationInfoObj = reactive({
  stationInfo: {},
  stationSt: {},
});

const { stationSt } = toRefs(stationInfoObj);

const flawDataRuleObj = reactive({
  flawDataRule: {},
});

const { flawDataRule } = toRefs(flawDataRuleObj);

const httpGetStState = async () => {
  const data = {};
  data.id = parseInt(route.params.id);
  const res = await GetStState(data);
  stationInfoObj.stationInfo = res.data;
};

const getStationInfo = (data) => {
  stationInfoObj.stationSt = data;
};

const resetAntvRender = () => {
  resetNum.value++;
};

const getChartRef = (chart) => {
  chartRef.chartRef = chart;
};

const filterFlawDataRule = (ruleForm) => {
  flawDataRuleObj.flawDataRule = ruleForm;
};

const getCurrentComponentByParam = (param) => {
  switch (param) {
    case 1:
      currentComponent.value = waveForm;
      break;
    case 2:
      currentComponent.value = waveFormDt;
      break;
    default:
      break;
  }
};

const getAllWeekLengthAndTime = ({ allWeekRunLength, allWeekRunTime }) => {
  weekRunLength.value = allWeekRunLength.value;
  weekRunTime.value = allWeekRunTime.value;
};

const changeCuverFlagVis = (flag) => {
  cuverFlag.value = flag;
};

const handleClickReport = () => {
  clickReport.value = true;
};

const changeReportDialog = (flag) => {
  clickReport.value = flag;
};

onMounted(() => {
  nextTick(() => {
    id.value = parseInt(route.params.id);
    httpGetStState();
    getCurrentComponentByParam(1);

    window.addEventListener(
      "resize",
      debounce(() => {
        if (window.innerHeight == window.screen.height) {
          isFullScreen.value = true;
   
        } else {
          isFullScreen.value = false;
        }
      })
    );

    // document.addEventListener("keydown", function (e) {
    //   if (e.key === "F11" || (e.shiftKey && e.key === "Enter")) {

    // });
  });
});

onUnmounted(() => {});
</script>

<style lang="scss" scoped>
.detail {
  background: url("../../assets/back.jpg") no-repeat center center;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  z-index: -10;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  color: #fff;

  .main-box {
    .top {
      display: flex;
      justify-content: space-around;
      .station-info {
        width: 25%;
        height: 5.25rem;
        .run-box {
          width: 70%;
          margin: 0 auto;
          height: 20%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0.125rem;
          .report {
            img {
              width: 0.75rem;
            }
          }
          .run-text {
            p {
              padding: 0.125rem;
              font-size: 0.2rem;
            }
          }
        }
      }

      .damage-list {
        width: 45%;
        // background-color: #021e33;
        height: 5.25rem;
      }

      .damage-img {
        width: 25%;
        // background-color: #021e33;
        height: 5.25rem;
        .damage-box {
          padding: 0.25rem;
          height: 100%;
          .title {
            p {
              color: #fff;
              font-size: 0.2rem;
            }
          }
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-around;
      margin-top: 0.375rem;
      .weekly-box {
        width: 35%;
        height: 6.25rem;
        // height: 8rem;
        // background-color: #021e33;
        .weekly-inner {
          padding: 0.25rem;
          .title {
            p {
              color: #fff;
              font-size: 0.2rem;
            }
          }
        }
      }
      .wave-form {
        width: 62%;
        height: 6.25rem;

        // background-color: #021e33;
        .top {
          padding: .25rem .25rem 0 .25rem;
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          .handle {
            width: 40%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .sensor-check {
              // margin-right: 0.25rem;
              padding: 0 0.375rem;
            }
          }
        }
        .rope-box {
          position: relative;
          top: -0.375rem;
        }
      }
    }
  }
}
.fixed-detail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
