<template>
  <div class="flaw-box">
    <div class="main">
      <reportHeader
        :basicReportInfo="basicReportInfo"
        :reportTitle="langObj.report.mrt_report"
        :pageSize="pageSize"
        :pageNum="pageNum"
      ></reportHeader>
      <div class="flaw-list-box">
        <div class="flaw-title">
          <p>{{langObj.report.injury_sequence}}</p>
        </div>
        <table cellspacing="1" border="1">
          <thead>
            <tr>
              <th colspan="10">
                {{langObj.report.injury_desc}}
              </th>
            </tr>
          </thead>

          <tbody v-if="!clickReport">
            <tr>
              <td rowspan="2">{{langObj.detail.number}}</td>
              <td rowspan="2">{{langObj.detail.rope_num}}</td>
              <td rowspan="2" style="width: 10%">{{langObj.detail.damage_location}}</td>
              <td rowspan="2" style="width: 10%">{{langObj.detail.damage_value}}</td>
              <td colspan="6">{{langObj.report.Damage_mark}}</td>
            </tr>
            <tr>
              <td >{{langObj.detail.Slight}}<em>○</em></td>
              <td>{{langObj.detail.Minor}}<em>●</em></td>
              <td>{{langObj.detail.Medium}}<em>▲</em></td>
              <td>{{langObj.detail.Serious}}<em>◆</em></td>
              <td>{{langObj.detail.Severe}}<em>■</em></td>
              <td>{{langObj.detail.Over_limit}}<em>★</em></td>
            </tr>

            <tr v-for="(item, index) in flawListGroup" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item?.[0] }}</td>
              <td>{{ item?.[3].toFixed(2) }}m</td>
              <td>{{ item?.[7].toFixed(1) }}%</td>
              <td class="icon">{{ item?.[11] == 0 ? "○" : "" }}</td>
              <td class="icon">{{ item?.[11] == 1 ? "●" : "" }}</td>
              <td class="icon">{{ item?.[11] == 2 ? "▲" : "" }}</td>
              <td class="icon">{{ item?.[11] == 3 ? "◆" : "" }}</td>
              <td class="icon">{{ item?.[11] == 4 ? "■" : "" }}</td>
              <td class="icon">{{ item?.[11] == 5 ? "★" : "" }}</td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td rowspan="2">{{langObj.detail.number}}</td>
              <td rowspan="2">{{langObj.detail.rope_num}}</td>
              <td rowspan="2" style="width: 15%">{{langObj.detail.damage_location}}</td>
              <td rowspan="2" style="width: 15%">{{langObj.detail.damage_value}}</td>
              <td colspan="6">{{langObj.report.Damage_mark}}</td>
            </tr>
            <tr>
              <td >{{langObj.detail.Slight}}<em>○</em></td>
              <td>{{langObj.detail.Minor}}<em>●</em></td>
              <td>{{langObj.detail.Medium}}<em>▲</em></td>
              <td>{{langObj.detail.Serious}}<em>◆</em></td>
              <td>{{langObj.detail.Severe}}<em>■</em></td>
              <td>{{langObj.detail.Over_limit}}<em>★</em></td>
            </tr>

            <tr v-for="(item, index) in flawListGroup" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item?.[0] }}</td>
              <td>{{ item?.[2].toFixed(2) }}m</td>
              <td>{{ item?.[3].toFixed(1) }}%</td>
              <td class="icon">{{ item?.[4] == 0 ? "○" : "" }}</td>
              <td class="icon">{{ item?.[4] == 1 ? "●" : "" }}</td>
              <td class="icon">{{ item?.[4] == 2 ? "▲" : "" }}</td>
              <td class="icon">{{ item?.[4] == 3 ? "◆" : "" }}</td>
              <td class="icon">{{ item?.[4] == 4 ? "■" : "" }}</td>
              <td class="icon">{{ item?.[4] == 5 ? "★" : "" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, watch ,computed} from "vue";
import reportHeader from "@/components/detail/report/child/reportHeader";
import { useStore } from "vuex";
export default {
  name: "",
  setup(props) {

    const flawListData = reactive([]);

    const store = useStore();

    const langObj = computed(() => {
      return store.state.lang.list;
    });


    watch(
      () => props.ruleFormParam,
      () => {
        //filterFlawDailyList();
      },
      { deep: true, immediate: false }
    );

    //这里有个坑 子组件接收父组件的数据比较慢 需要监听
    watch(
      () => props.flawDailyDataClick,
      () => {
        //filterFlawDailyList();
      },
      { deep: true, immediate: true }
    );

    onMounted(() => {
      nextTick(() => {});
    });

    return {
      flawListData,
      langObj
    };
  },

  data() {
    return {};
  },

  mounted() {},
  props: {
    flawListGroup: Array,
    basicReportInfo: Object,
    pageNum: [Number, String],
    pageSize: [Number, String],
    clickReport: Boolean,
  },
  components: {
    reportHeader,
  },
};
</script>

<style lang="scss" scoped>
.flaw-box {
  width: 1000px;
  background-color: #fff;
  .main {
    width: 90%;
    margin: 0 auto;
    color: #333;
    padding: 20px 0;
    .flaw-list-box {
      margin-top: 10px;
      .flaw-title {
        text-align: center;
        padding: 15px;
        font-size: 18px;
        font-weight: 600;
        border-top: 1px solid #333;
      }
      table {
        width: 100%;
        font-size: 14px;
        margin: 0 auto;
        thead {
          th {
            padding: 15px 10px;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            border-left: none;
          }
        }
        tr {
          td {
            padding: 13px 10px;
            text-align: center;
            position: relative;
            em {
              position: absolute;
              display: block;
              right: 5px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 15px;
            }
          }
          .icon {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>