export function computedStationFlawData(flawList) {
    const flawLevel = ["轻微", "轻度", "中度", "较重", "严重", "超限"];
    let flawListArr = [];

    for (let i = 0; i < flawLevel.length; i++) {
        flawListArr[i] = 0;
    }
    flawList.forEach((item) => {
        flawListArr[item[4]] += 1;
    });
    return flawListArr;
}


export const computedflawLevelArr = (filterFlawDaily) => {
    const flawLevel = ["轻微", "轻度", "中度", "较重", "严重", "超限"];
    const flawLevelArr = [];
    for (let i = 0; i < flawLevel.length; i++) {
        flawLevelArr[i] = 0;
    }

    if (filterFlawDaily.length > 0) {
        filterFlawDaily.forEach((item) => {
            flawLevelArr[item[11]] += 1;
        });
    }
    return flawLevelArr;
};

export const computedStationflawLevelArr = (filterFlawDaily) => {
    const flawLevel = ["轻微", "轻度", "中度", "较重", "严重", "超限"];
    const flawLevelArr = [];
    for (let i = 0; i < flawLevel.length; i++) {
        flawLevelArr[i] = 0;
    }

    if (filterFlawDaily.length > 0) {
        filterFlawDaily.forEach((item) => {
            flawLevelArr[item[4]] += 1;
        });
    }
    return flawLevelArr;
};


export function getFlawEchartsOptions(flawData, isReport = false, lang="zh-CN" ,bottom = "10%", top = "10%") {
    const flawDataTemp = [... flawData ]; //下面的排序会修改 flawData 
    const newArr = flawData.sort((a, b) => b - a);
    let max = newArr[0] + 10;
    let newMax = max - (max % 5);
    let dataLang = [];
    let fontSize = 15;
    if(lang == "zh-CN"){
        dataLang =  ["轻微", "轻度", "中度", "较重", "严重", "超限"];
        fontSize = 15;
    }else if(lang == "en"){
        dataLang =  ["Slight", "Minor", "Medium", "Serious", "Severe", "Over_limit"];
        fontSize = 12;
    }
    const option = {
        grid: {
            bottom: bottom,
            top: top,
            left:"15px",
            right:"15px",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: dataLang,
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#11212D",
                    width: 2,
                    type: "solid"
                },
            }, //背景线条颜色
            axisLabel: {
                show: true,
                interval: 0,  //设置间隔为0
                textStyle: {
                    color: "#59A0B7",
                    fontWeight: "600",
                    fontSize: fontSize
                },
            },
        },
        yAxis: {
            type: "value",
            boundaryGap: true,
            min: 0,
            max: newMax,
            interval: newMax / 5, // 强制设置坐标轴分割间隔。
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dashed",
                    color: "#404370",
                    width: 1
                },
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#12222F",
                    width: 2,
                    type: "solid"
                },
            }, //背景线条颜色
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#404370",
                    fontWeight: "600",
                    fontSize: 15
                },
            }, //文字颜色
        },
        series: [{
            label: {
                normal: {
                    show: true,
                    position: "top",
                    formatter: function (v) {
                        var val = v.value;
                        return val == 0 ? '0' : val;
                    },

                    color: isReport ? "#333" : "#FFF",
                },
            },
            itemStyle: {
                normal: {
                    color: function name(params) {
                        var colorList = [
                            "#80FF80",
                            "#00DE00",
                            "#FFFF00",
                            "#FF8040",
                            "#FF0000",
                            "#8B3A3A"
                        ];
                        return colorList[params.dataIndex];
                    },
                },
            },
            data: flawDataTemp,
            type: "bar",
        }, ],
    };

    return option;
}