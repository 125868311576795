<template>
  <div class="header">
    <div class="title">
      <p>{{ reportTitle }}</p>
    </div>
    <div class="pageNum">
      <p class="num">{{langObj.report.number}}：{{ basicReportInfo.reportNum }}</p>
      <p class="page">
        <span>{{langObj.report.di}}{{ pageNum }}{{langObj.report.page}}</span><span>{{langObj.report.total}}{{ pageSize }}{{langObj.report.page}}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "",
  setup() {
    const store = useStore();

    const langObj = computed(() => {
      return store.state.lang.list;
    });
    return {
      langObj,
    };
  },
  data() {
    return {};
  },

  mounted() {},

  props: {
    basicReportInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    reportTitle: {
      type: String,
      default: "",
    },
    pageSize: {
      type: [Number, String],
      default: 5,
    },
    pageNum: {
      type: [Number, String],
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 100px;
  width: 100%;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 3px;
    font-family: "宋体";
  }
  .pageNum {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .page {
      & span:nth-of-type(1) {
        margin-right: 10px;
      }
    }
  }
}
</style>