import {
    GetHistoryCurve,
    GetSingleCurve
} from "@/common/api/index/api.js";
import {
    reactive
} from "vue";


export const httpGetHistoryCurveHook = async (currentDate, id) => {

    const ruleFrom = reactive({
        id: 0,
        fname: "",
        date: "2023-03-26",
        type: 0,
        isFilterPole: 0,
    });

    const cuverData = reactive({
        sensors: 0, // 传感器数量
        start_pos: 0, // 开始位置
        end_pos: 0, // 结束位置
        sample_step: 0, // 步长 采样进度
    });

    ruleFrom.id = parseInt(id);
    ruleFrom.date = currentDate;
    const res = await GetHistoryCurve(ruleFrom);

    let sencorAntvData = "";
    let echartsDataRope = "";
    let echartsRopeDataGroup = "";
    let sencorEchartsData = "";
    let sensors = 0;
    if (res.data.data) {
        sensors = cuverData.sensors = res.data.sensors;
        cuverData.start_pos = res.data.start_pos;
        cuverData.end_pos = res.data.end_pos;

        cuverData.sample_step = res.data.sample_step;
        const filterData = base64toBlob(res.data.data); //先把返回的数据转成uncode编码
        const FilterDataList = filterCurveDataBySensors(filterData, cuverData); //按照传感器数据量进行分组
        const {
            echartsData,
            echartsGroupData
        } = getRopeAntvG2Data(FilterDataList, cuverData); //组装钢丝绳所属的波形图数据

        echartsDataRope = echartsData;
        echartsRopeDataGroup = echartsGroupData;

        const
            sencorGroupData = filterSencorEchartsData(
                FilterDataList,
                cuverData
            ); // 组装传感器所需波形图

        sencorEchartsData = sencorGroupData;
    }
    return {
        sencorAntvData,
        echartsDataRope,
        echartsRopeDataGroup,
        sensors,
        sencorEchartsData
    };
};


export const httpGetSingleCurveHook = async (id) => {

    const cuverData = reactive({
        sensors: 0, // 传感器数量
        start_pos: 0, // 开始位置
        end_pos: 0, // 结束位置
        sample_step: 0, // 步长 采样进度
    });

    const ruleFrom = reactive({
        id: id,
        type: 0,
        isFilterPole: 0,
    });

    let echartsDataRope = "";
    let echartsRopeDataGroup = "";
    let sencorEchartsData = "";
    let sensors = 0;
    const res = await GetSingleCurve(ruleFrom);

    if (res.data.data) {
        sensors = cuverData.sensors = res.data.sensors;
        cuverData.start_pos = res.data.start_pos;
        cuverData.end_pos = res.data.end_pos;
        cuverData.end_pos = res.data.end_pos;
        cuverData.sample_step = res.data.sample_step;

        const filterData = base64toBlob(res.data.data); //先把返回的数据转成uncode编码
        const FilterDataList = filterCurveDataBySensors(filterData, cuverData); //按照传感器数据量进行分组
        const {
            echartsData,
            echartsGroupData
        } = getRopeAntvG2Data(FilterDataList, cuverData); //组装钢丝绳所属的波形图数据

        echartsDataRope = echartsData;
        echartsRopeDataGroup =   echartsGroupData;  
        const sencorGroupData = filterSencorEchartsData(
            FilterDataList,
            cuverData
        ); // 组装传感器所需波形图

        sencorEchartsData = sencorGroupData;

    }

    return {
        echartsDataRope,
        echartsRopeDataGroup,
        sencorEchartsData,
        sensors
    };
};



/**
 *  将base64转为Unicode规则编码
 */
export const base64toBlob = (base64, type) => {
    let bstr = atob(base64, type),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n); // 转换编码后才可以使用charCodeAt 找到Unicode编码
    }

    return u8arr;
};



/**
 * 
 * @param {*} blobData  转为Unicode规则编码的数据
 * @param {*} cuverData  波形相关的参数
 * @returns 
 */
export const filterCurveDataBySensors = (blobData, cuverData) => {
    //根据传感器数计算返回包含几根绳的二维数组
    var newData = [];
    var tmpArr = [];
    for (let i = 0; i < blobData.length; i++) {
        tmpArr.push(blobData[i]);
        if ((i + 1) % cuverData.sensors == 0 && i != 0) {
            newData.push(tmpArr.reverse());
            tmpArr = [];
        }
    }

    if (tmpArr.length > 0) {
        newData.push(tmpArr);
    }

    return newData;
};


export const getRopeAntvG2Data = (FilterDataList, cuverData) => {
    const filterAllData = getCuverDataByAdd(FilterDataList); //计算每组数据的平均数  二维数组
    const echartsData = computerRopeEchatsData(filterAllData, cuverData);

    //兼容多绳进行处理,之前只有单绳
    const ropeGroupList = getRopeGroupDataList(FilterDataList); //按照感器数6对所有传感器分组 三维数组  

    const ropeGroupData = getCuverGroupDataByAdd(ropeGroupList) // 计算钢丝绳分组后的，每组数据的平均数  
    const echartsGroupData = filterSencorEchartsData(ropeGroupData, cuverData); // 根据分组后的数据，组装echarts多维数据
    return {
        echartsData, //单绳的数据结构
        echartsGroupData //多绳的数据结构
    };
};



/**
 * 兼容多绳处理
 * @param {*} FilterDataList  按照所有传感器分组后的数据
 * @param {*} ropeSensorNum   一条钢丝绳对应多少传感器
 */
const getRopeGroupDataList = (FilterDataList, ropeSensorNum = 6) => {

    const ropeGroupList = [];
    for (let i = 0; i < FilterDataList.length; i++) {
        const tempGroup = [];
        let tempArr = [];
        for (let j = 0; j < FilterDataList[i].length; j++) {
            tempArr.push(FilterDataList[i][j]);
            if ((j + 1) % ropeSensorNum == 0 && j != 0) {
                tempGroup.push(tempArr);
                tempArr = [];
            }
        }
        ropeGroupList.push(tempGroup);
    }

    return ropeGroupList;
}

/**
 * //兼容多绳处理
 * @param {*} ropeGroupList 计算多绳每组数据的平均数
 */
const getCuverGroupDataByAdd = (ropeGroupList) => {
    const ropeGroupAve = [];
    for (let i = 0; i < ropeGroupList.length; i++) {
        const ropeGroup = [];
        for (let j = 0; j < ropeGroupList[i].length; j++) {
            let averageData = parseFloat(averageCuverData(ropeGroupList[i][j]).toFixed(1))
            ropeGroup.push(averageData);
        }
        ropeGroupAve.push(ropeGroup);
    }

    return ropeGroupAve;
}


const computerRopeEchatsData = (filterAllData, cuverData, filter = 20) => {
    const newEchartsData = [];
    let sampleStep = cuverData.sample_step / 1000;
    let startPos = cuverData.start_pos;
    for (let i = 0; i < filterAllData.length; i++) {
        startPos += sampleStep;
        let tempArr = [];
        if (i % filter == 0) {
            tempArr[0] = parseFloat(startPos.toFixed(3));
            tempArr[1] = filterAllData[i];
            newEchartsData.push(tempArr);
        }
    }
    return newEchartsData;
}


const getCuverDataByAdd = (filterDataList) => {
    const allArr = [];
    for (let i = 0; i < filterDataList.length; i++) {
        allArr.push(parseFloat(averageCuverData(filterDataList[i]).toFixed(1)));
    }
    return allArr;
};

const averageCuverData = (arr) => {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
        sum += arr[i];
    }
    return sum / arr.length;
};



const filterSencorEchartsData = (filterAllData, cuverData, filter = 20, interval = 100) => {
    const sencorGroupData = [];
    let singleArr = [];
    let sampleStep = cuverData.sample_step / 1000;
    let startPos = cuverData.start_pos;
    for (let i = 0; i < filterAllData[0].length; i++) {
        singleArr = [];
        startPos = cuverData.start_pos;
        for (let j = 0; j < filterAllData.length; j++) {
            startPos += sampleStep;
            const tempObj = [];
            if (j % filter == 0) {
                tempObj[0] = parseFloat(startPos.toFixed(3));
                tempObj[1] = filterAllData[j][i] + i * interval;
                singleArr.push(tempObj);
            }
        }
        sencorGroupData.push(singleArr);
    }

    return sencorGroupData;
};



//此方法可以将Antv G2 转成base64图片格式
export const toDataURL = (chart) => {
    const canvas = chart.getCanvas();
    const renderer = chart.renderer;
    const canvasDom = canvas.get("el");
    // const ctx  = canvasDom.getContext('2d');
    //canvasDom.rotate((-190 * Math.PI) / 180);
    let dataURL = "";
    if (renderer === "svg") {
        const clone = canvasDom.cloneNode(true);
        const svgDocType = document.implementation.createDocumentType(
            "svg",
            "-//W3C//DTD SVG 1.1//EN",
            "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"
        );
        const svgDoc = document.implementation.createDocument(
            "http://www.w3.org/2000/svg",
            "svg",
            svgDocType
        );
        svgDoc.replaceChild(clone, svgDoc.documentElement);
        const svgData = new XMLSerializer().serializeToString(svgDoc);
        dataURL =
            "data:image/svg+xml;charset=utf8," + encodeURIComponent(svgData);
    } else if (renderer === "canvas") {
        dataURL = canvasDom.toDataURL("image/png");
    }
    return dataURL;
};

//签完名的图片旋转处理
// export async function rotateBase64Img(src, edg = 180, callback) {
//     var canvas = document.createElement('canvas');
//     var ctx = canvas.getContext('2d');
//     var imgW; //图片宽度
//     var imgH; //图片高度
//     var size; //canvas初始大小
//     if (edg % 90 != 0) {
//         console.error('旋转角度必须是90的倍数!');
//         throw '旋转角度必须是90的倍数!';
//     }
//     edg < 0 && (edg = (edg % 360) + 360);
//     const quadrant = (edg / 90) % 4; //旋转象限

//     const cutCoor = {
//         sx: 0,
//         sy: 0,
//         ex: 0,
//         ey: 0
//     }; //裁剪坐标
//     var image = new Image();
//     image.crossOrigin = 'anonymous';
//     image.src = src;
//     image.onload = function () {
//         imgW = image.width;
//         imgH = image.height;
//         size = imgW > imgH ? imgW : imgH;
//         canvas.width = size * 2;
//         canvas.height = size * 2;
//         switch (quadrant) {
//             case 0:
//                 cutCoor.sx = size;
//                 cutCoor.sy = size;
//                 cutCoor.ex = size + imgW;
//                 cutCoor.ey = size + imgH;
//                 break;
//             case 1:
//                 cutCoor.sx = size - imgH;
//                 cutCoor.sy = size;
//                 cutCoor.ex = size;
//                 cutCoor.ey = size + imgW;
//                 break;
//             case 2:
//                 cutCoor.sx = size - imgW;
//                 cutCoor.sy = size - imgH;
//                 cutCoor.ex = size;
//                 cutCoor.ey = size;
//                 break;
//             case 3:
//                 cutCoor.sx = size;
//                 cutCoor.sy = size - imgW;
//                 cutCoor.ex = size + imgH;
//                 cutCoor.ey = size + imgW;
//                 break;
//         }
//         ctx ?.translate(size, size);
//         ctx ?.rotate((edg * Math.PI) / 180);
//         //drawImage向画布上绘制图片
//         ctx ?.drawImage(image, 0, 0);
//         //getImageData() 复制画布上指定矩形的像素数据

//         var imgData = ctx ?.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);

//         if (quadrant % 2 == 0) {
//             canvas.width = imgW;
//             canvas.height = imgH;
//         } else {
//             canvas.width = imgH;
//             canvas.height = imgW;
//         }
//         //putImageData() 将图像数据放回画布
//         ctx ?.putImageData(imgData, 0, 0);
//         callback(canvas.toDataURL('image/png'));
//     };

// }