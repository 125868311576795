<template>
  <div class="mrt-box">
    <div class="main">
      <reportHeader
        :basicReportInfo="basicReportInfo"
        :reportTitle="langObj.report.mrt_report"
        :pageSize="pageSize"
        :pageNum="2"
      ></reportHeader>
      <div class="table-box">
        <table cellspacing="1" border="1" class="basic-table">
          <thead>
            <tr>
              <th colspan="5">
                {{ langObj.report.basic_reflects }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="11" class="basic-text" style="width: 30px">
                {{ langObj.report.basic_info }}
              </td>
              <td>{{ langObj.report.detection_object }}</td>
              <td colspan="3">{{ basicReportInfo.testObj }}</td>
            </tr>
            <tr>
              <td style="width: 19%">{{ langObj.report.manufacturer }}</td>
              <td style="">{{ wireRopeData.ropeManuf }}</td>
              <td style="width: 19%">{{ langObj.report.test_length }}</td>
              <td style="">
                {{ basicReportInfo.testLength?.toFixed(1) }}m
              </td>
            </tr>
            <tr>
              <td>{{ langObj.report.application }}</td>
              <td>{{ wireRopeData.ropePurpose }}</td>
              <td>{{ langObj.report.rope_length }}</td>
              <td>{{ wireRopeData.ropeLenth }}m</td>
            </tr>
            <tr>
              <td>{{ langObj.report.Specs_models }}</td>
              <td>{{ wireRopeData.ropeSpecific }}</td>
              <td>{{ langObj.report.rope_diameter }}</td>
              <td>{{ wireRopeData.ropeDm }}mm</td>
            </tr>
            <tr>
              <td>{{ langObj.report.material }}</td>
              <td>{{ wireRopeData.ropeMaterials }}</td>
              <td>{{ langObj.report.rope_number }}</td>
              <td>{{ wireRopeData.ropeNum }}</td>
            </tr>
            <tr>
              <td>{{ langObj.report.core_steel }}</td>
              <td>{{ wireRopeData.ropeMaterialx }}</td>
              <td>{{ langObj.report.cross_section }}</td>
              <td>{{ wireRopeData.ropeEmArea }}</td>
            </tr>
            <tr>
              <td>{{ langObj.report.surface_treatment }}</td>
              <td>{{ wireRopeData.ropeMaterialbm }}</td>
              <td>{{ langObj.report.twisting_method }}</td>
              <td>{{ wireRopeData.ropeTwistWay }}</td>
            </tr>
            <tr>
              <td>{{ langObj.report.breaking_load }}</td>
              <td>{{ wireRopeData.ropeFknFact }}</td>
              <td>{{ langObj.report.lubricant }}</td>
              <td>{{ wireRopeData.ropeLubricant }}</td>
            </tr>
            <tr>
              <td>{{ langObj.report.install_date }}</td>
              <td>{{ wireRopeData.ropeUsedate }}</td>
              <td>{{ langObj.report.scrap_limit }}</td>
              <td>{{ wireRopeData.scrapLimit }}</td>
            </tr>
            <tr>
              <td>{{ langObj.detail.total_run_duration }}</td>
              <td>{{ wireRopeData.currentTimeAll }}h</td>
              <td>{{ langObj.detail.total_mileage }}</td>
              <td>{{ wireRopeData.currentLengthAll }}km</td>
            </tr>
            <tr>
              <td>{{ langObj.report.according }}</td>
              <td colspan="3">GBT 9770-2003、GBT 8918-2006、GBT 5972-2016</td>
            </tr>
          </tbody>
        </table>
        <table cellspacing="1" border="1" class="flaw-table">
          <tbody>
            <tr>
              <td style="width: 30px" class="basic-text">
                {{ langObj.report.test_result }}
              </td>
              <td style="width: auto; vertical-align: top">
                <tr>
                  <td colspan="2" style="width:34%">{{ langObj.report.conclusion }}</td>
                  <td colspan="4" style="width: 66%">{{ resultLevel }}</td>
                </tr>
                <tr>
                  <td colspan="5">{{ langObj.report.five_flaw }}</td>
                </tr>
                <tr class="list">
                  <td class="line" ></td>
                  <td style="width: ">{{ langObj.detail.rope_num }}</td>
                  <td style="width: ">
                    {{ langObj.detail.damage_location }}
                  </td>
                  <td style="width: ">{{ langObj.detail.damage_value }}</td>
                  <td style="width: ">{{ langObj.detail.damage_level }}</td>
                </tr>

                <tr
                  class="list"
                  v-for="(item, index) in flawListFive"
                  :key="index"
                >
                  <td style="width: 15%">NO.{{ index + 1 }}</td>
                  <td>{{ item[0] }}</td>
                  <td>
                    {{
                      !clickReport ? item[3].toFixed(2) : item[2].toFixed(2)
                    }}m
                  </td>
                  <td>
                    {{
                      !clickReport ? item[7].toFixed(1) : item[3].toFixed(2)
                    }}%
                  </td>
                  <td
                    :class="[
                      !clickReport
                        ? filterDamageLevelColor(item[11],lang)
                        : filterDamageLevelColor(item[4],lang),
                    ]"
                  >
                    {{
                      !clickReport
                        ? filterDamageLevelText(item[11],lang)
                        : filterDamageLevelText(item[4],lang)
                    }}
                  </td>
                </tr>
              </td>
              <td style="width: 45%; vertical-align: top" class="flaw-count">
                <tr>
                  <td colspan="6">{{ langObj.report.damage_statistics }}</td>
                </tr>
                <tr>
                  <td>{{ langObj.detail.Slight }}</td>
                  <td>{{ langObj.detail.Minor }}</td>
                  <td>{{ langObj.detail.Medium }}</td>
                  <td>{{ langObj.detail.Serious }}</td>
                  <td>{{ langObj.detail.Severe }}</td>
                  <td>{{ langObj.detail.Over_limit }}</td>
                </tr>
                <tr>
                  <td>{{ flawLevelData[0] }}</td>
                  <td>{{ flawLevelData[1] }}</td>
                  <td>{{ flawLevelData[2] }}</td>
                  <td>{{ flawLevelData[3] }}</td>
                  <td>{{ flawLevelData[4] }}</td>
                  <td>{{ flawLevelData[5] }}</td>
                </tr>
                <tr>
                  <td colspan="6">
                    <div
                      class="harm-echart"
                      ref="harmEcharts"
                      id="harmEchatsId"
                    ></div>
                  </td>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
        <table cellspacing="1" border="1" class="notes-table">
          <tr>
            <td style="width: 30px; writing-mode: vertical-rl">
              {{ langObj.report.description }}
            </td>
            <td class="notes-text">
              <div class="title">{{ langObj.report.mrt_tt }}</div>
              <p>
                <span>{{ langObj.report.mrt_a }}</span>
                <span>({{ langObj.report.stop_use }})</span>
              </p>
              <p>
                <span>{{ langObj.report.mrt_b }}</span>
                <span>({{ langObj.report.carefully_use }})</span>
              </p>
              <p>
                <span>{{ langObj.report.mrt_c }}</span>
                <span>({{ langObj.report.carefully_use1 }})</span>
              </p>
              <p>
                <span>{{ langObj.report.mrt_d }}</span>
                <span>({{ langObj.report.verify_use }})</span>
              </p>
              <p>
                <span>{{ langObj.report.mrt_e }}</span>
                <span>({{ langObj.report.continued_use }})</span>
              </p>
               <p>
                <span>{{ langObj.report.mrt_f }}</span>
                <span>({{ langObj.report.continued_use }})</span>
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import { nextTick, onMounted, reactive, watch, ref, computed } from "vue";
import reportHeader from "@/components/detail/report/child/reportHeader";
import {
  filterDamageLevelTextHook,
  filterDamageLevelColorHook,
  httpGetStationFlawDataHook,
} from "@/hooks/detail/detail.js"; //定义 ruleFrom rules

import {
  filterFlawDailyByRuleForm,
  filterStationFlawDailyByRuleForm,
} from "@/hooks/detail/report.js";

import {
  computedflawLevelArr,
  computedStationflawLevelArr,
  getFlawEchartsOptions,
} from "@/hooks/detail/echarts/flawEcharts.js"; //定义 ruleFrom rules
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const props = defineProps({
  wireRopeData: Object,
  basicReportInfo: Object,
  ruleFormParam: Object,
  flawDailyDataClick: Object,
  pageSize: [Number, String],
  clickReport: Boolean,
});

const flawListFive = reactive([]);
const harmEcharts = ref(null);
const echartsObj = ref(null);
const flawLevelData = reactive([]);
const optionsConfig = reactive({
  option: {},
});

const route = useRoute();
const ruleForm = reactive({
  id: 0,
  filter_value: "",
  filter_level: 0,
  sort: 0,
  reverse: 0,
});

let resultLevel = ref("");

const store = useStore();

const langObj = computed(() => {
  return store.state.lang.list;
});

const lang = computed(() => {
  return store.state.lang.lang;
});

//初始化echarts
const initHarmEchats = () => {
  //echartsObj.value = echarts.init(document.querySelector("#harmEchatsId"));
  echartsObj.value = echarts.init(harmEcharts.value);
  echartsObj.value.setOption(optionsConfig.option);
};

const getFlawEchartsOptionsHook = (filterFlawDaily) => {
  let flawLevelArr = "";
  if (!props.clickReport) {
    flawLevelArr = computedflawLevelArr(filterFlawDaily);
  } else {
    flawLevelArr = computedStationflawLevelArr(filterFlawDaily);
  }

  flawLevelData.length = 0;
  flawLevelData.push(...flawLevelArr);
  optionsConfig.option = getFlawEchartsOptions(flawLevelArr, true,lang.value, "0", "5%");
  initHarmEchats();
};

const filterFlawDailyList = async () => {
  let flawDailyList = "";
  let filterFlawDaily = "";
  let filterFlawDailySort = "";
    // 当日检测 只添加了testLength 一个键值对
  if (Object.keys(props.flawDailyDataClick).length > 1) {
    flawDailyList = props.flawDailyDataClick.countFlaw;
    flawDailyList = flawDailyList == undefined ? [] : flawDailyList;
    filterFlawDaily = filterFlawDailyByRuleForm(flawDailyList, props);
    filterFlawDailySort = filterFlawListFiveData(filterFlawDaily);
  } else {
    //获取当天日期的数据
    ruleForm.id = route.params.id;
    const flawDailyList = await httpGetStationFlawDataHook(ruleForm, false);
    filterFlawDaily = filterStationFlawDailyByRuleForm(flawDailyList, props);
    filterFlawDailySort = filterStationFlawListFiveData(filterFlawDaily);
  }

  // 计算损伤分级统计的数据 组装Echarts柱状图的数据
  getFlawEchartsOptionsHook(filterFlawDaily);

  //按损伤量值倒序

  flawListFive.length = 0;
  flawListFive.push(...filterFlawDailySort.splice(0, 5));

  damageLevelResult();
};

const filterFlawListFiveData = (filterFlawDaily) => {
  return filterFlawDaily.sort((a, b) => b[7] - a[7]);
};

const filterStationFlawListFiveData = (filterFlawDaily) => {
  return filterFlawDaily.sort((a, b) => b[3] - a[3]);
};

const filterDamageLevelText = (state,lang) => {
  return filterDamageLevelTextHook(state,lang);
};
const filterDamageLevelColor = (state,lang) => {
  return filterDamageLevelColorHook(state,lang);
};

const damageLevelResult = () => {
  let damageLevel = "";
  if (!props.clickReport) {
    damageLevel = flawListFive.map((v) => v[11]);
  } else {
    damageLevel = flawListFive.map((v) => v[4]);
  }

  if (lang.value == "zh-CN") {
    if (damageLevel.includes(5)) {
      resultLevel.value = "A级-停止使用";
    } else if (damageLevel.includes(4)) {
      resultLevel.value = "B级-谨慎使用";
    } else if (damageLevel.includes(3)) {
      resultLevel.value = "C级-谨慎使用";
    } else if (damageLevel.includes(2)) {
      resultLevel.value = "D级-验证使用";
    } else if (damageLevel.includes(1)) {
      resultLevel.value = "E级-继续使用";
    } else {
      resultLevel.value = "F级-继续使用";
    }
  } else if (lang.value == "en") {
    if (damageLevel.includes(5)) {
      resultLevel.value = "A-Stop using";
    } else if (damageLevel.includes(4)) {
      resultLevel.value = "B-Use with caution";
    } else if (damageLevel.includes(3)) {
      resultLevel.value = "C-Use with caution";
    } else if (damageLevel.includes(2)) {
      resultLevel.value = "D-Verify usage";
    } else if (damageLevel.includes(1)) {
      resultLevel.value = "E-continued use";
    } else {
      resultLevel.value = "D-continued use";
    }
  }
};

watch(
  () => props.ruleFormParam,
  () => {
    filterFlawDailyList();
  },
  { deep: true, immediate: false }
);

watch(
  () => props.flawDailyDataClick,
  () => {
    filterFlawDailyList();
  },
  { deep: true, immediate: false }
);

onMounted(() => {
  nextTick(() => {
    //filterFlawDailyList()
  });
});
</script>

<style lang="scss" scoped>
.mrt-box {
  width: 1000px;
  background-color: #fff;
  .main {
    width: 90%;
    margin: 0 auto;
    color: #333;
    padding: 20px 0;
    .header {
      height: 100px;
      background-color: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 3px;
      }
      .pageNum {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .page {
          & span:nth-of-type(1) {
            margin-right: 10px;
          }
        }
      }
    }
    .table-box {
      margin-top: 10px;
      table {
        width: 100%;
        font-size: 14px;
        margin: 0 auto;
        thead {
          th {
            padding: 10px;
            font-weight: normal;
            text-align: center;
          }
        }
        tr {
          td {
            padding: 13px 10px;
            text-align: center;
          }
        }
      }
      .flaw-table {
        tr.list {
          .line {
            padding: 20px 0;
            position: relative;
            width: 15%;
            background: linear-gradient(
              25deg,
              transparent 48.5%,
              #333 49.5%,
              #333 36.5%,
              transparent 51.5%
            );
          }
        }
        .flaw-count {
          td {
            width: 45px;
          }
        }
      }
      .notes-table {
        .notes-text {
          text-align: left;
        }
        p {
          display: flex;
          justify-content: space-between;
          margin: 12px 0;
          span {
            display: block;
          }
          & span:nth-of-type(1) {
            width: 100%;
          }
          & span:nth-of-type(2) {
            width: 40%;
          }
        }
      }

      .basic-text {
        writing-mode: vertical-rl;
        letter-spacing: 1px;
        vertical-align: middle;
        font-size: 16px;
      }

      .harm-echart {
        width: 100%;
        height: 260px;
      }
    }
  }
}
</style>