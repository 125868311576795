export const initCurverEchatsOptions = (echatsData, chartScale) => {

    const options = {
        backgroundColor: "",
        // title: {
        //   text: "",
        //   subtext: "",
        // },
        grid: [{
            top: 0,
            bottom: 0,
            left: 5,
            right: 20,
            containLabel: true,
        }, ],
        //color: [this.ropeColor[this.index1]], //关键加上这句话，legend的颜色和折线的自定义颜色就一致了
        tooltip: {
            trigger: 'axis',
            formatter: function (param) {

                let str = "";
                param.forEach((item) => {
                    str += "实时位置</br>" + item.marker + "位置：" + item.value[0].toFixed(2) + "m";
                })
                return str;
            }
        },
        // legend: {
        //     data: ["绳子1"],
        //     icon: "rect",
        //     right: 50 + "%",
        //     top: 0,
        //     itemWidth: 30,
        //     textStyle: {
        //         //图例文字的样式
        //         color: "#fff",
        //         fontSize: 13,
        //     },
        // },
        //xAxis: this.xAxisData,
        xAxis: [{
            // gridIndex: 0,
            // data: [0,10,20,30,40,50,10,20],
            min: Math.floor(chartScale.minLength * 10) / 10, //最小值
            max: Math.floor(chartScale.maxLength * 10) / 10, //最大值
            // boundaryGap: false,

            splitNumber: 15,
            //inverse: 'true', //是否是反向坐标轴。
            splitLine: {
                //保留网格线
                show: true,
                lineStyle: {
                    color: "#021E33",
                    width: 1,
                    type: "dashed",
                },
            },
            // splitArea: { show: true }, //去除网格区域
            axisLine: {
                lineStyle: {
                    type: "solid",
                    //  color: "this.axisLabelColor",
                    width: "1",
                },
                onZero: false
            },
            axisLabel: {
                show: true,
                showMaxLabel: true,
                showMinLabel: true,
                interval: 0, // 0：则显示所有的；1：隔一个显示一个
                textStyle: {
                    //color: this.axisLabelColor,
                },
                formatter: function (value) {

                    return value + "m"
                },
                // 默认x轴字体大小
                fontSize: 12,
                margin: 15,
                rotate: 0,
            },
        }, ],
        //  yAxis: this.yAxisData,
        yAxis: [{
            //   gridIndex: 0,
            type: "value",
            max: chartScale.maxValue,
            min: chartScale.minValue,
            position: "left",
            splitNumber: 0,

            splitLine: {
                //保留网格线
                show: false,
                lineStyle: {
                    color: "#E0E0E0",
                    width: 1,
                    type: "dashed",
                },
            },
            axisLabel: {
                show: false,
                interval: 0, // 0：则显示所有的；1：隔一个显示一个
                textStyle: {
                    //color: this.axisLabelColor,
                },
                // 默认x轴字体大小
                fontSize: 12,
                margin: 15,
                rotate: 60,
            },
            // splitArea: { show: true }, //去除网格区域
            axisLine: {
                //y轴线设置
                show: false,
                onZero: false, //是否在0刻度
                lineStyle: {
                    type: "solid",
                    color: "#BDBFC2",
                    width: "1",
                },
            },
        }, ],

        dataZoom: [
            // {
            //     type: 'slider',
            //     show: false, //show属性为false不展示缩放手柄，为true展示缩放手柄
            //     // handleSize: 88  该属性是缩放手柄的宽度
            // },
            {
                type: 'inside', // 放大和缩小
                orient: 'vertical',
                disabled: !chartScale.zoomScaleY,
            },
            {
                type: 'inside',
                disabled: chartScale.zoomScaleY,
            }
        ],
        series: [{
            name: "length",
            // data: [
            //     [10, 10],
            //     [11, 10],
            //     [30, 20],
            //     [40, 10],
            //     [60, 20],
            // ],
            data: echatsData,
            type: "line",
            symbol: "circle",
            showSymbol: false,

            lineStyle: {
                //折线颜色大小
                type: "solid", //'dotted'虚线 'solid'实线
                color: "red",
                width: 1,
            },
        }, ],

    };

    return options;
}

export const initCurverSencorEchatsOptionsDetail = (echatsSencorData, chartScale) => {

    const ropeColor = ["#E11D33", "#47CE4B", "#7DAFF3", "#C07DDE", "#EB8B46", "#EDD745"];
    const seriesDataArr = [];
    for (let i = 0; i < echatsSencorData.length; i++) {
        const seriesObj = {
            name: "length",
            //  data: [
            //     [10, 10],
            //     [11, 10],
            //     [30, 20],
            //     [40, 10],
            //     [60, 20],
            // ],
            data: echatsSencorData[i],
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
                //折线颜色大小
                type: "solid", //'dotted'虚线 'solid'实线
                color: ropeColor[i % ropeColor.length],
                width: 1,
            },
        };
        seriesDataArr.push(seriesObj)
    }

    //const seriesArr = [];

    const options = {
        backgroundColor: "",
        // title: {
        //   text: "",
        //   subtext: "",
        // },
        grid: [{
            top: 0,
            bottom: 0,
            left: 20,
            right: 20,
            containLabel: true,
        }, ],
        //color: [this.ropeColor[this.index1]], //关键加上这句话，legend的颜色和折线的自定义颜色就一致了
        tooltip: {
            trigger: 'axis',
            formatter: function (param) {
                let str = "";

                param.forEach((item) => {
                    if (item.seriesIndex == 0) str += "当前位置</br>";

                    str += "<span style='color:" +ropeColor[(param.length - (item.seriesIndex % ropeColor.length) - 1) % ropeColor.length] + "'>绳" + (item.seriesIndex + 1) + "</span>&nbsp&nbsp" + item.value[0].toFixed(2) + "m</br>";
                })
                return str;
            }
        },
        // legend: {
        //     data: ["绳子1"],
        //     icon: "rect",
        //     right: 50 + "%",
        //     top: 0,
        //     itemWidth: 30,
        //     textStyle: {
        //         //图例文字的样式
        //         color: "#fff",
        //         fontSize: 13,
        //     },
        // },
        xAxis: [{
            // gridIndex: 0,
            // data: [0,10,20,30,40,50,10,20],
            min: Math.floor(chartScale.minLength * 10) / 10, //最小值
            max: Math.floor(chartScale.maxLength * 10) / 10, //最大值
            // boundaryGap: false,

            splitNumber: 20,
            //inverse: 'true', //是否是反向坐标轴。
            splitLine: {
                //保留网格线
                show: true,
                lineStyle: {
                    color: "#404370",
                    width: 1,
                    type: "dashed",
                },
            },
            // splitArea: { show: true }, //去除网格区域
            axisLine: {
                onZero: false, //是否在0刻度
                lineStyle: {
                    type: "solid",
                    color: "#FFF",

                    width: "1",
                },
            },
            axisLabel: {
                show: true,
                showMaxLabel: true,
                showMinLabel: true,
                interval: 0, // 0：则显示所有的；1：隔一个显示一个
                textStyle: {
                    color: "#FFF",
                },
                formatter: function (value) {
                    return value + "m"
                },
                // 默认x轴字体大小
                fontSize: 12,
                margin: 15,
                rotate: 0,
            },
        }, ],
        //  yAxis: this.yAxisData,
        yAxis: [{
            //   gridIndex: 0,
            type: "value",
            max: chartScale.maxValue,
            min: chartScale.minValue,
            position: "left",
            splitNumber: 10,

            splitLine: {
                //保留网格线
                show: true,
                lineStyle: {
                    color: "#404370",
                    width: 1,
                    type: "dashed",
                },
            },
            axisLabel: {
                show: false,
                interval: 0, // 0：则显示所有的；1：隔一个显示一个
                textStyle: {
                    //color: this.axisLabelColor,
                },
                // 默认x轴字体大小
                fontSize: 12,
                margin: 15,
                rotate: 60,
            },
            // splitArea: { show: true }, //去除网格区域
            axisLine: {
                //y轴线设置
                show: true,
                onZero: false, //是否在0刻度
                lineStyle: {
                    type: "solid",
                    color: "#4E6272",
                    width: "1",
                },
            },
            axisTick: {
                show: false //刻度线
            }
        }, ],

        dataZoom: [
            // {
            //     type: 'slider',
            //     show: true, //show属性为false不展示缩放手柄，为true展示缩放手柄
            //     // handleSize: 88  该属性是缩放手柄的宽度
            // },
            {
                type: 'inside', //  Y轴放大和缩小
                orient: 'vertical',
                disabled: !chartScale.zoomScaleY,
            },
            {
                type: 'inside',
                // disabled: chartScale.zoomScaleY,
            }
        ],
        // series: [{
        //     name: "length",
        //     // data: [
        //     //     [10, 10],
        //     //     [11, 10],
        //     //     [30, 20],
        //     //     [40, 10],
        //     //     [60, 20],
        //     // ],
        //     //data: echatsData,
        //     type: "line",
        //     symbol: "circle",
        //     showSymbol: false,
        //     lineStyle: {
        //         //折线颜色大小
        //         type: "solid", //'dotted'虚线 'solid'实线
        //         color: "red",
        //         width: 1,
        //     },
        // }, ],
        series: seriesDataArr
    };

    return options;
}


export const initCurverSencorEchatsOptions = (echatsSencorData, chartScale, isReport = true) => {

    const ropeColor = ["#E11D33", "#47CE4B", "#7DAFF3", "#C07DDE", "#EB8B46", "#EDD745"];
    const seriesDataArr = [];
    for (let i = 0; i < echatsSencorData.length; i++) {
        const seriesObj = {
            name: "length",
            //  data: [
            //     [10, 10],
            //     [11, 10],
            //     [30, 20],
            //     [40, 10],
            //     [60, 20],
            // ],
            data: echatsSencorData[i],
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
                //折线颜色大小
                type: "solid", //'dotted'虚线 'solid'实线
                color: ropeColor[i % ropeColor.length],
                width: 1,
            },
        };
        seriesDataArr.push(seriesObj)
    }

    //const seriesArr = [];

    const options = {
        backgroundColor: "",
        // title: {
        //   text: "",
        //   subtext: "",
        // },
        grid: [{
            top: 0,
            bottom: 0,
            left: 20,
            right: 20,
            containLabel: true,
        }, ],
        //color: [this.ropeColor[this.index1]], //关键加上这句话，legend的颜色和折线的自定义颜色就一致了
        tooltip: {
            trigger: 'axis',
            formatter: function (param) {
                let str = "";

                param.forEach((item) => {
                    if (item.seriesIndex == 0) str += "当前位置</br>";

                    str += "<span style='color:" + ropeColor[(param.length - (item.seriesIndex % ropeColor.length) - 1) % ropeColor.length] + "'>传感器" + (item.seriesIndex + 1) + "</span>&nbsp&nbsp" + item.value[0].toFixed(2) + "m</br>";
                })
                return str;
            }
        },
        // legend: {
        //     data: ["绳子1"],
        //     icon: "rect",
        //     right: 50 + "%",
        //     top: 0,
        //     itemWidth: 30,
        //     textStyle: {
        //         //图例文字的样式
        //         color: "#fff",
        //         fontSize: 13,
        //     },
        // },
        xAxis: [{
            // gridIndex: 0,
            // data: [0,10,20,30,40,50,10,20],
            min: Math.floor(chartScale.minLength * 10) / 10, //最小值
            max: Math.floor(chartScale.maxLength * 10) / 10, //最大值
            // boundaryGap: false,

            splitNumber: 20,
            //inverse: 'true', //是否是反向坐标轴。
            splitLine: {
                //保留网格线
                show: isReport ? false : true,
                lineStyle: {
                    color: "#404370",
                    width: 1,
                    type: "dashed",
                },
            },
            // splitArea: { show: true }, //去除网格区域
            axisLine: {
                onZero: false, //是否在0刻度
                lineStyle: {
                    type: "solid",
                    //  color: "this.axisLabelColor",
                    width: "1",
                },
            },
            axisLabel: {
                show: true,
                showMaxLabel: true,
                showMinLabel: true,
                interval: 0, // 0：则显示所有的；1：隔一个显示一个
                textStyle: {
                    //color: this.axisLabelColor,
                },
                formatter: function (value) {
                    return value + "m"
                },
                // 默认x轴字体大小
                fontSize: 12,
                margin: 15,
                rotate: 0,
            },
        }, ],
        //  yAxis: this.yAxisData,
        yAxis: [{
            //   gridIndex: 0,
            type: "value",
            max: chartScale.maxValue,
            min: chartScale.minValue,
            position: "left",
            splitNumber: 20,

            splitLine: {
                //保留网格线
                show: isReport ? false : true,
                lineStyle: {
                    color: "#404370",
                    width: 1,
                    type: "dashed",
                },
            },
            axisLabel: {
                show: false,
                interval: 0, // 0：则显示所有的；1：隔一个显示一个
                textStyle: {
                    //color: this.axisLabelColor,
                },
                // 默认x轴字体大小
                fontSize: 12,
                margin: 15,
                rotate: 60,
            },
            // splitArea: { show: true }, //去除网格区域
            axisLine: {
                //y轴线设置
                show: true,
                onZero: false, //是否在0刻度
                lineStyle: {
                    type: "solid",
                    color: "#BDBFC2",
                    width: "1",
                },
            },
        }, ],

        dataZoom: [
            // {
            //     type: 'slider',
            //     show: true, //show属性为false不展示缩放手柄，为true展示缩放手柄
            //     // handleSize: 88  该属性是缩放手柄的宽度
            // },
            {
                type: 'inside', //  Y轴放大和缩小
                orient: 'vertical',
                disabled: !chartScale.zoomScaleY,
            },
            {
                type: 'inside',
                // disabled: chartScale.zoomScaleY,
            }
        ],
        // series: [{
        //     name: "length",
        //     // data: [
        //     //     [10, 10],
        //     //     [11, 10],
        //     //     [30, 20],
        //     //     [40, 10],
        //     //     [60, 20],
        //     // ],
        //     //data: echatsData,
        //     type: "line",
        //     symbol: "circle",
        //     showSymbol: false,
        //     lineStyle: {
        //         //折线颜色大小
        //         type: "solid", //'dotted'虚线 'solid'实线
        //         color: "red",
        //         width: 1,
        //     },
        // }, ],
        series: seriesDataArr
    };

    return options;
}