import {
    reactive
} from "vue";
import {
    GetStationFlawData
} from "@/common/api/index/api.js";


// filter 是否返回原始数据
export async function httpGetStationFlawDataHook(data, filter = true) {

    const flawListArr = reactive([]);
    const res = await GetStationFlawData(data);
    const flawList = res.data.flaw_list;
    for (let i = 0; i < flawList.length; i++) {
        const tempArr = {};
        tempArr.id = flawList[i][0]; //损伤ID号
        tempArr.ropeNum = flawList[i][1]; //绳号
        tempArr.damageSite = flawList[i][2].toFixed(2) + "m"; //损伤位置
        tempArr.damageValue = flawList[i][3].toFixed(1) + "%"; //损伤量值
        tempArr.damageLevel = flawList[i][4]; //损伤等级
        tempArr.diameterMeter1 = flawList[i][5]; //直径测量值1
        tempArr.diameterMeter2 = flawList[i][6]; //直径测量值2
        tempArr.diameterMeter3 = flawList[i][7]; //直径测量值3
        tempArr.damageFloor = flawList[i][8]; //损伤位置对应的楼层数
        tempArr.isMark = flawList[i][9]; //是否标记
        tempArr.gripperNumber = flawList[i][10]; //是否标记
        tempArr.rowStartSite = flawList[i][11]; //横向起始位置
        tempArr.rowEndSite = flawList[i][12]; //横向结束位置
        tempArr.valueExpand = flawList[i][13]; //量值扩展值
        flawListArr.push(tempArr)
    }

    if (!filter) {
        return flawList;
    }

    return flawListArr
}

export function filterDamageLevelTextHook(level, lang) {
    
    if (lang == "zh-CN") {
        switch (level) {
            case 0:
                return "轻微";
            case 1:
                return "轻度";
            case 2:
                return "中度";
            case 3:
                return "较重";
            case 4:
                return "严重";
            case 5:
                return "超限";
            default:
                break;
        }
    } else if (lang == "en") {
        switch (level) {
            case 0:
                return "Slight";
            case 1:
                return "Minor";
            case 2:
                return "Medium";
            case 3:
                return "Serious";
            case 4:
                return "Severe";
            case 5:
                return "Over_limit";
            default:
                break;
        }
    }

}

export function filterDamageLevelColorHook(level) {
    switch (level) {
        case 0:
            return "qingwei";
        case 1:
            return "qingdu";
        case 2:
            return "zhongdu";
        case 3:
            return "jiaozhong";
        case 4:
            return "yanzhong";
        case 5:
            return "chaoxian";
        default:
            break;
    }
}

export function filterDamageLevelBackColorHook(level) {
    switch (level) {
        case 0:
            return "qingwei-bd";
        case 1:
            return "qingdu-bd";
        case 2:
            return "zhongdu-bd";
        case 3:
            return "jiaozhong-bd";
        case 4:
            return "yanzhong-bd";
        case 5:
            return "chaoxian-bd";
        default:
            break;
    }
}