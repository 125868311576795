<template>
  <div class="report-container">
    <div class="main">
      <reportHeader
        :basicReportInfo="basicReportInfo"
        reportTitle="MRT检测报告"
        :pageSize="pageSize"
        :pageNum="pageNum"
      ></reportHeader>
      <div class="title">
        <p>当前状态弱磁检测图</p>
      </div>
      <!-- <div :id="'report_sensor' + index" class="report-wave"></div> -->
      <div
        :id="'sensorEcharts' + index"
        ref="curverEcharts"
        class="report-wave"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
// import { getSencorAntvG2Charts } from "@/hooks/detail/antv/report/sensorChartsRt.js"; //引入封装的Antv G2 组件
import { initCurverSencorEchatsOptions } from "@/hooks/detail/echarts/curver/curverEcharts.js";
import { nextTick, onMounted, watch, reactive } from "vue";
// import { Chart } from "@antv/g2";
import reportHeader from "@/components/detail/report/child/reportHeader";
export default {
  name: "",
  setup(props) {
    // let chart = null;
    let echartsSensorObj = null;
    let chartScale = reactive({
      maxLength: 0,
      minLength: 0,
      maxValue: 0,
      minValue: 0,
      zoomScaleY: false,
    });

    const initCurverEchartsFun = (echartsSencorData) => {
      let echartDataSort = [...echartsSencorData];
      getMaxMin(echartDataSort);
      echartsSensorObj = echarts.init(
        document.querySelector("#sensorEcharts" + props.index)
      );
      const options = initCurverSencorEchatsOptions(
        echartsSencorData,
        chartScale
      );
      echartsSensorObj.setOption(options);
    };

    const getMaxMin = (echartsSencorData) => {
      let sortLength = echartsSencorData[0].map((v) => v[0]);
      const sencorDataSort = sortLength.sort((a, b) => b - a);
      chartScale.maxLength = sencorDataSort[0];
      chartScale.minLength = sencorDataSort[sencorDataSort.length - 1];

      let sortValueMax = echartsSencorData[echartsSencorData.length - 1].map(
        (v) => v[1]
      );
      sortValueMax = sortValueMax.sort((a, b) => b - a);
      chartScale.maxValue = sortValueMax[0] + 100;
      let sortValueMin = echartsSencorData[0].map((v) => v[1]);
      sortValueMin = sortValueMin.sort((a, b) => b - a);
      chartScale.minValue = sortValueMin[sortValueMin.length - 1] - 100;
    };

    watch(
      () => props.flawDailyDataClick,
      () => {
        if (!props.reportHide) initCurverEchartsFun(props.sensorData);
      },
      { deep: true, immediate: false }
    );

    watch(
      () => props.reportHide,
      () => {
        if (!props.reportHide) initCurverEchartsFun(props.sensorData);
      },
      { deep: true, immediate: false }
    );

    onMounted(() => {
      nextTick(() => {
        if (!props.reportHide) {
          initCurverEchartsFun(props.sensorData);
        }
      });
    });

    return {};
  },
  data() {
    return {};
  },

  mounted() {},

  props: {
    basicReportInfo: Object, //  报告基本信息
    flawDailyDataClick: Object, //点击当前日期的损伤数据信息
    ruleFormParam: Object, //参数过滤
    pageNum: [Number, String],
    pageSize: [Number, String],
    reportHide: Boolean,
    index: [Number, String],
    sensorData: Array,
  },
  components: {
    reportHeader,
  },
};
</script>

<style lang="scss" scoped>
.report-container {
  width: 1000px;
  background-color: #fff;
  .main {
    width: 90%;
    margin: 0 auto;
    color: #333;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      width: 100%;
      text-align: center;
      padding: 15px;
      font-size: 18px;
      font-weight: 600;
      border-top: 1px solid #333;
      margin-top: 10px;
    }
    .report-wave {
      width: 1000px;
      height: 900px;
      background-color: #fff;
      transform: rotate(90deg);
      margin-top: 55px;
    }
  }
}
</style>