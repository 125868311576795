export function scrollToSlow(dist) {
  // 1.1 返回文档在垂直方向已滚动的像素值
  const now = window.scrollY;
  // 1.2 目标位置（文章信息区域的高度）
  // let dist = document.querySelector('.article-container').offsetHeight
  // 1.3 可滚动高度 = 整个文档的高度 - 浏览器窗口的视口（viewport）高度
  const avalibleHeight =
    document.documentElement.scrollHeight - window.innerHeight;

  // 2.1 如果【目标高度】 大于 【可滚动的高度】
  if (dist > avalibleHeight) {
    // 2.2 就把目标高度，设置为可滚动的高度
    dist = avalibleHeight;
  }

  // 3. 动态计算出步长值
  const step = (dist - now) / 10;
  setTimeout(() => {
    // 4.2 如果当前的滚动的距离不大于 1px，则直接滚动到目标位置，并退出递归
    if (Math.abs(step) <= 1) {
      return window.scrollTo(0, dist)
    }
    // 4.1 每隔 10ms 执行一次滚动，并递归地进行下一次的滚动
    window.scrollTo(0, now + step)
    // document.documentElement.scrollTop = now + step;
    scrollToSlow(dist);
  }, 10);
}

//函数节流——用户操作小于300秒不执行 否则执行
export function throttle(fn, interval = 300) {
  let canRun = true;
  return function () {
    if (!canRun) return;
    canRun = false;
    setTimeout(() => {
      fn.apply(this, arguments);
      canRun = true;
    }, interval);
  };
}

//函数节流——用户操作小于300秒不执行 否则执行
export function throttle1(fn, interval = 300) {
  let canRun = true;
  return function () {
    if (canRun) {
      setTimeout(() => {
        fn.apply(this, arguments);
        canRun = true;
      }, interval);
      canRun = false;
    }

  };
}


//函数防抖——只在用户最后一次操作 之后的300秒执行
export function debounce(fn, interval = 300) {
  let timeout = null;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, arguments);
    }, interval);
  };
}