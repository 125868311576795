import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const filterHtmlToCanvas = async (DomRef) => {
  return html2canvas(DomRef, {
    dpi: 96, // 分辨率
    scale: 2, // 设置缩放
    useCORS: true, // 允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。,
    bgcolor: "#ffffff", // 应该这样写
    logging: false, // 打印日志用的 可以不加默认为false
  }).then((canvas) => {
    return canvas;
  });
};

export const getHomeCanvasPdfImage = async (canvas, pdfObj) => {

  const contentWidth = canvas.width;
  const contentHeight = canvas.height;
  // 一页pdf显示html页面生成的canvas高度;
  const pageHeight = (contentWidth / 592.28) * 841.89;
  // 未生成pdf的html页面高度
  let leftHeight = contentHeight;
  // 页面偏移
  let position = 0;
  // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
  const imgWidth = 595.28;
  const imgHeight = (595.28 / contentWidth) * contentHeight;
  const pageData = canvas.toDataURL("image/jpeg", 1.0);
  if (leftHeight < pageHeight) {
    // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
    pdfObj.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
  } else {

    // 分页
    while (leftHeight > 0) {
      pdfObj.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
      leftHeight -= pageHeight;
      position -= 841.89;
      // 避免添加空白页
      if (leftHeight > 0) {
        pdfObj.addPage();
      }
    }
  }

  // 可动态生成
  //pdf.save(`文件名.pdf`);
};

export const getFlawDailyCanvasPdfImage = (canvas, pdfObj) => {
  const contentWidth = canvas.width;
  const contentHeight = canvas.height;
  // 一页pdf显示html页面生成的canvas高度;
  const pageHeight = (contentWidth / 592.28) * 841.89;
  // 未生成pdf的html页面高度
  let leftHeight = contentHeight;
  // 页面偏移
  let position = 0;
  // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
  const imgWidth = 595.28;
  const imgHeight = (595.28 / contentWidth) * contentHeight;
  const pageData = canvas.toDataURL("image/jpeg", 1.0);

  if (leftHeight < pageHeight) {
    pdfObj.addPage();
    // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
    pdfObj.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
  } else {
    // 分页
    while (leftHeight > 0) {
      pdfObj.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
      leftHeight -= pageHeight;
      position -= 841.89;
      // 避免添加空白页
      if (leftHeight > 0) {
        pdfObj.addPage();
      }
    }
  }
  // 可动态生成
  //pdf.save(`文件名.pdf`);
};



export const getCommonCanvasPdfImage = (canvas, pdfObj, homePage = false) => {
  const contentWidth = canvas.width;
  const contentHeight = canvas.height;
  // 一页pdf显示html页面生成的canvas高度;
  const pageHeight = (contentWidth / 592.28) * 841.89;
  // 未生成pdf的html页面高度
  let leftHeight = contentHeight;
  // 页面偏移
  let position = 0;
  // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
  const imgWidth = 595.28;
  const imgHeight = (595.28 / contentWidth) * contentHeight;
  const pageData = canvas.toDataURL("image/jpeg", 1.0);

  if (leftHeight < pageHeight) {
    if (!homePage) pdfObj.addPage();
    // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
    pdfObj.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
  } else {
    // 分页
    while (leftHeight > 0) {
      if (leftHeight > 0) {
        pdfObj.addPage();
      }
      pdfObj.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
      leftHeight -= pageHeight;
      position -= 841.89;
      // 避免添加空白页

    }
  }
  // 可动态生成
  //pdf.save(`文件名.pdf`);
};



//根据过滤参数过滤损伤数据
export const filterFlawDailyByRuleForm = (flawDailyList, props) => {
  const filterFlawDaily = [];
  const ruleFormParam = props.ruleFormParam;
  for (let i = 0; i < flawDailyList.length; i++) {
    if (
      flawDailyList[i][7] > ruleFormParam.filter_value &&
      flawDailyList[i][11] >= ruleFormParam.filter_level
    ) {
      filterFlawDaily.push(flawDailyList[i]);
    }
  }
  return filterFlawDaily;
};


//过滤站点的
export const filterStationFlawDailyByRuleForm = (flawDailyList, props) => {
  const filterFlawDaily = [];
  const ruleFormParam = props.ruleFormParam;
  for (let i = 0; i < flawDailyList.length; i++) {
    if (
      flawDailyList[i][3] > ruleFormParam.filter_value &&
      flawDailyList[i][4] >= ruleFormParam.filter_level
    ) {
      filterFlawDaily.push(flawDailyList[i]);
    }
  }
  return filterFlawDaily;
};


export const computedFlawListByPage = (flawListData, group = 25) => {
  const newFlawList = [];
  if (flawListData.length > 0) {
    let tempArr = [];
    for (let i = 0; i < flawListData.length; i++) {
      tempArr.push(flawListData[i]);
      if ((i + 1) % group == 0 && i != 0) {
        newFlawList.push(tempArr);
        tempArr = [];
      }
      if (i == flawListData.length - 1 && tempArr.length > 0) {
        newFlawList.push(tempArr);
      }
    }

  }

  return newFlawList;
};



export const handleClickExportPdf1 = () => {
  const testObj = document.getElementById("testObj");
  html2canvas(testObj, {
    dpi: 96, // 分辨率
    //   scale: 2, // 设置缩放
    useCORS: true, // 允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。,
    bgcolor: "#ffffff", // 应该这样写
    logging: false, // 打印日志用的 可以不加默认为false
  }).then((canvas) => {
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    // 一页pdf显示html页面生成的canvas高度;
    const pageHeight = (contentWidth / 592.28) * 841.89;
    // 未生成pdf的html页面高度
    let leftHeight = contentHeight;
    // 页面偏移
    let position = 0;
    // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    const imgWidth = 595.28;
    const imgHeight = (595.28 / contentWidth) * contentHeight;
    const ctx = canvas.getContext("2d");
    // 添加水印
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.rotate((25 * Math.PI) / 180);
    ctx.font = "20px Microsoft Yahei";
    ctx.fillStyle = "rgba(184, 184, 184, 0.8)";

    const pageData = canvas.toDataURL("image/jpeg", 1.0);

    const pdf = new jsPDF("", "pt", "a4");
    console.log(leftHeight, pageHeight);
    if (leftHeight < pageHeight) {
      // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
      pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
    } else {
      // 分页
      while (leftHeight > 0) {
        pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= 841.89;
        // 避免添加空白页
        if (leftHeight > 0) {
          pdf.addPage();
        }
      }
    }

    // 可动态生成
    pdf.save(`文件名.pdf`);
  });
};